import React from "react";
import { v4 as uuidv4 } from "uuid";

export function getViewerId() {
  let viewerId = getStorageValue("viewerId");
  if (!viewerId) {
    viewerId = uuidv4();
    setStorageValue("viewerId", viewerId);
  }

  return viewerId;
}

export function getStorageValue(key, defaultValue) {
  // getting stored value
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

export function setStorageValue(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export const useLocalStorage = key => {
  const [value, setValue] = React.useState(() => localStorage.getItem(key));

  React.useEffect(() => {
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  }, [key, value]);

  return [value, setValue];
};

export function getToken() {
  return localStorage.getItem("token");
}

export function setToken(token) {
  localStorage.setItem("token", token);
}

export function removeToken() {
  return localStorage.removeItem("token");
}
