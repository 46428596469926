import React from "react";
import Bar from "../../components/Bar/Bar";
import FooterView from "../../components/FooterView";
import HomeHeader from "./ui/HomeHeader";

import Article1 from "./ui/Article1";
import HomePresentHassleFree from "./ui/HomePresentHassleFree";

export default function ArticleScreen() {
  return (
    <div style={{ background: "#fff" }}>
      <Bar />
      <HomeHeader />
      <Article1 />
      <HomePresentHassleFree />

      <FooterView />
    </div>
  );
}
