import { atom, useAtom } from "jotai";

const userAtom = atom(null);

export function useUser() {
  return useAtom(userAtom);
}

const userDataAtom = atom(null);

export function useUserData() {
  return useAtom(userDataAtom);
}

export function useLoggedIn() {
  const [user] = useUser();

  return user && user.id;
}

const slideIndexAtom = atom(0);

export function useSlideIndex() {
  return useAtom(slideIndexAtom);
}
