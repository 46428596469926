import Dialog from "@mui/material/Dialog";
import React from "react";
import Hidden from "@mui/material/Hidden";

const ResponsiveDialog = ({ children, ...props }) => (
  <>
    <Hidden mdDown>
      <Dialog {...props} fullWidth>
        {children}
      </Dialog>
    </Hidden>

    <Hidden mdUp>
      <Dialog {...props} fullWidth>
        {children}
      </Dialog>
    </Hidden>
  </>
);

ResponsiveDialog.propTypes = {};

export default ResponsiveDialog;
