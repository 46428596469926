import AttachmentIcon from "@mui/icons-material/Attachment";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Checkbox, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import React from "react";
import { NumericFormat } from "react-number-format";

export function CheckboxInput({ label, size, value, onValue }) {
  return (
    <FormControlLabel
      {...{ label }}
      control={
        <Checkbox {...{ size }} checked={value} onChange={event => onValue(event.target.checked)} />
      }
    />
  );
}

export function SwitchInput({
  label,
  style,
  value,
  onValue,
  containerStyle,
  labelPlacement,
  ...props
}) {
  return (
    <FormControl component="fieldset" style={containerStyle}>
      <FormControlLabel
        {...{ label, style }}
        labelPlacement={labelPlacement || "start"}
        control={
          <Switch
            {...props}
            color="primary"
            checked={!!value}
            onChange={event => onValue(event.target.checked)}
          />
        }
      />
    </FormControl>
  );
}

export function FileInput({ id, value, label, onValue, onDelete }) {
  if (value) {
    return (
      <div
        style={{
          marginTop: 16,
          paddingTop: 16,
          paddingLeft: 16,
          borderRadius: 4,
          paddingRight: 16,
          paddingBottom: 8,
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #ccc",
        }}>
        <div
          style={{
            left: 8,
            top: -10,
            fontSize: 12,
            paddingLeft: 8,
            paddingRight: 8,
            position: "absolute",
            backgroundColor: "#fff",
            color: "rgba(0, 0, 0, 0.6)",
          }}>
          {label}
        </div>

        <a href={value} target="_blank" rel="noopener noreferrer">
          {value}
        </a>

        <input
          // multiple
          type="file"
          accept="*/*"
          onChange={onValue}
          style={{ display: "none" }}
          id={id}
        />
        <label htmlFor={id} style={{ paddingTop: 8 }}>
          <Button component="span" variant="outlined" onClick={onDelete} style={{ marginLeft: 16 }}>
            Change
          </Button>
        </label>
      </div>
    );
  }
  return (
    <div
      style={{
        height: 56,
        marginTop: 16,
        borderRadius: 4,
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #ccc",
      }}>
      <input
        // multiple
        type="file"
        accept="*/*"
        onChange={onValue}
        style={{ display: "none" }}
        id={id}
      />
      <label
        htmlFor={id}
        style={{
          flex: 1,
          height: 56,
          paddingTop: 8,
          display: "block",
        }}>
        <Button variant="text" component="span">
          <AttachmentIcon style={{ marginLeft: 8, marginRight: 10 }} /> {label}
        </Button>
      </label>
      {false && (
        <div style={{ position: "absolute", right: 0 }}>
          <Button variant="text" component="span">
            <CloseIcon />
          </Button>
        </div>
      )}
    </div>
  );
}

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
    />
  );
});

function TextInput({ onValue, ...props }) {
  return (
    <TextField
      fullWidth
      {...props}
      variant="outlined"
      onChange={event => onValue(event.target.value)}
    />
  );
}

export function MoneyInput(props) {
  return (
    <TextInput
      {...props}
      InputProps={{
        inputComponent: NumericFormatCustom,
      }}
      variant="standard"
    />
  );
}
