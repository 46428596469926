import {
  PROPERTY_ADD_MEDIA,
  PROPERTY_BUY,
  PROPERTY_CREATE,
  PROPERTY_DELETE,
  PROPERTY_GET,
  PROPERTY_LIKE,
  PROPERTY_REMOVE_MEDIA,
  PROPERTY_UNLIKE,
  PROPERTY_UPDATE,
  PROPERTY_VIEWED,
  LIKED_PROPERTIES,
  PENDING_PROPERTIES,
  POPULAR_PROPERTIES,
  PUBLISHED_PROPERTIES,
  PURCHASED_PROPERTIES,
  SEARCH_PROPERTIES,
  USER_PROPERTIES,
} from "./graphql";
import client, { queryClient } from "../client";
import { useQuery } from "react-query";
import Queries from "../Queries";
import Api from "../Api";

function propertyGet(id) {
  return client
    .query({
      variables: { id },
      query: PROPERTY_GET,
    })
    .then(response => response.propertyGet);
}

function popularProperties(skip, limit) {
  return client
    .query({
      query: POPULAR_PROPERTIES,
      variables: {
        skip,
        limit,
      },
    })
    .then(response => response.popularProperties);
}

function publishedProperties(skip, limit) {
  return client
    .query({
      query: PUBLISHED_PROPERTIES,
      variables: {
        skip,
        limit,
      },
    })
    .then(response => response.publishedProperties);
}

function pendingProperties(skip, limit) {
  return client
    .query({
      query: PENDING_PROPERTIES,
      variables: {
        skip,
        limit,
      },
    })
    .then(response => response.pendingProperties);
}

function userProperties(userId, skip, limit) {
  return client
    .query({
      query: USER_PROPERTIES,
      variables: {
        skip,
        limit,
        userId,
      },
    })
    .then(response => response.userProperties);
}

function likedProperties(skip, limit) {
  return client
    .query({
      query: LIKED_PROPERTIES,
      variables: {
        skip,
        limit,
      },
    })
    .then(response => response.likedProperties);
}

function purchasedProperties(skip, limit, iCloudUserId) {
  return client
    .query({
      query: PURCHASED_PROPERTIES,
      variables: {
        skip,
        limit,
        iCloudUserId,
      },
    })
    .then(response => response.purchasedProperties);
}

function searchProperties(keywords, location, skip, limit) {
  return client
    .query({
      query: SEARCH_PROPERTIES,
      variables: {
        skip,
        limit,
        keywords,
        location,
      },
    })
    .then(response => response.searchProperties);
}

function propertyBuy(id, { orderId, appleReceipt, googleReceipt, iCloudUserId }) {
  return client
    .mutate({
      variables: {
        id,
        orderId,
        appleReceipt,
        googleReceipt,
        iCloudUserId,
      },
      mutation: PROPERTY_BUY,
    })
    .then(response => response.propertyBuy);
}

function propertyLike(id) {
  return client
    .mutate({
      variables: {
        id,
      },
      mutation: PROPERTY_LIKE,
    })
    .then(response => response.propertyLike)
    .then(invalidate);
}

function propertyUnlike(id) {
  return client
    .mutate({
      variables: {
        id,
      },
      mutation: PROPERTY_UNLIKE,
    })
    .then(response => response.propertyUnlike)
    .then(invalidate);
}

function propertyViewed(id) {
  return client
    .mutate({
      variables: {
        id,
      },
      mutation: PROPERTY_VIEWED,
    })
    .then(response => response.propertyViewed);
}

function propertyDelete(id) {
  return client
    .mutate({
      variables: {
        id,
      },
      mutation: PROPERTY_DELETE,
    })
    .then(response => response.propertyDelete);
}

function propertyCreate({
  zip,
  city,
  state,
  street,
  slideIndex,
  crossStreets,

  size,
  price,
  status,
  nrBeds,
  nrBaths,

  agent,
  email,
  phone,

  notes,
  description,
}) {
  return client
    .mutate({
      variables: {
        zip,
        city,
        state,
        street,
        slideIndex,
        crossStreets,

        size,
        price,
        status,
        nrBeds,
        nrBaths,

        agent,
        email,
        phone,

        notes,
        description,
      },
      mutation: PROPERTY_CREATE,
    })
    .then(response => response.propertyCreate)
    .then(invalidate);
}

function propertyUpdate(
  id,
  {
    zip,
    city,
    state,
    street,
    slideIndex,
    crossStreets,

    size,
    price,
    status,
    nrBeds,
    nrBaths,

    agent,
    email,
    phone,

    notes,
    description,
  }
) {
  return client
    .mutate({
      variables: {
        id,
        zip,
        city,
        state,
        street,
        slideIndex,
        crossStreets,

        size,
        price,
        status,
        nrBeds,
        nrBaths,

        agent,
        email,
        phone,

        notes,
        description,
      },
      mutation: PROPERTY_UPDATE,
    })
    .then(response => response.propertyUpdate)
    .then(invalidate);
}

function propertyAddMedia(mediaId, propertyId) {
  return client
    .mutate({
      variables: {
        mediaId,
        propertyId,
      },
      mutation: PROPERTY_ADD_MEDIA,
    })
    .then(response => response.propertyAddMedia)
    .then(invalidate);
}

function propertyRemoveMedia(mediaId, propertyId) {
  return client
    .mutate({
      variables: {
        mediaId,
        propertyId,
      },
      mutation: PROPERTY_REMOVE_MEDIA,
    })
    .then(response => response.propertyRemoveMedia)
    .then(invalidate);
}

function invalidate(res) {
  queryClient.invalidateQueries(Queries.PROPERTY);
  return res;
}

export function useProperties() {
  return useQuery(Queries.POPULAR_PROPERTIES, () => Api.property.popularProperties());
}

export function useProperty(propertyId) {
  return useQuery([Queries.PROPERTY, propertyId], () => Api.property.propertyGet(propertyId));
}

export default {
  propertyGet,
  propertyBuy,
  propertyLike,
  propertyUnlike,
  propertyViewed,
  propertyDelete,
  propertyCreate,
  propertyUpdate,
  propertyAddMedia,
  propertyRemoveMedia,
  userProperties,
  likedProperties,
  searchProperties,
  popularProperties,
  pendingProperties,
  publishedProperties,
  purchasedProperties,
};
