import { gql } from "graphql-request";

import { PROPERTY_MODEL } from "../models";

export const PROPERTY_ADD_MEDIA = gql`
  mutation propertyAddMedia($mediaId: String, $propertyId: String) {
    propertyAddMedia(mediaId: $mediaId, propertyId: $propertyId) {
      ${PROPERTY_MODEL}
    }
  }
`;

export const PROPERTY_REMOVE_MEDIA = gql`
  mutation propertyRemoveMedia($mediaId: String, $propertyId:String) {
    propertyRemoveMedia(mediaId: $mediaId, propertyId: $propertyId) {
      ${PROPERTY_MODEL}
    }
  }
`;

export const PROPERTY_BUY = gql`
  mutation propertyBuy(
    $id: String,
    $orderId: String
    $appleReceipt: String
    $googleReceipt: String
    $iCloudUserId: String
  ) {
    propertyBuy(
      id: $id
      orderId: $orderId
      appleReceipt: $appleReceipt
      googleReceipt: $googleReceipt
      iCloudUserId: $iCloudUserId
    ) {
      ${PROPERTY_MODEL}
    }
  }
`;

export const PROPERTY_LIKE = gql`
  mutation propertyLike(
    $id: String
  ) {
    propertyLike(
      id: $id
    ) {
      ${PROPERTY_MODEL}
    }
  }
`;

export const PROPERTY_UNLIKE = gql`
  mutation propertyUnlike(
    $id: String
  ) {
    propertyUnlike(
      id: $id
    ) {
      ${PROPERTY_MODEL}
    }
  }
`;

export const PROPERTY_VIEWED = gql`
  mutation propertyViewed(
    $id: String
  ) {
    propertyViewed(
      id: $id
    ) {
      ${PROPERTY_MODEL}
    }
  }
`;

export const PROPERTY_DELETE = gql`
  mutation propertyDelete(
    $id: String
  ) {
    propertyDelete(
      id: $id
    ) {
      ${PROPERTY_MODEL}
    }
  }
`;

export const PROPERTY_CREATE = gql`
  mutation propertyCreate(
    $zip: String
    $state: String
    $street: String
    $city: String
    $slideIndex: Int
    $crossStreets: String

    $size: Float
    $price: Float
    $status: String
    $nrBeds: Float
    $nrBaths: Float

    $agent: String
    $email: String
    $phone: String

    $notes: String
    $description: String
  ) {
    propertyCreate(
      property:{
        zip: $zip
        state: $state
        street: $street
        city: $city
        slideIndex: $slideIndex
        crossStreets: $crossStreets

        size: $size
        price: $price
        status: $status
        nrBeds: $nrBeds
        nrBaths: $nrBaths

        agent: $agent
        email: $email
        phone: $phone

        notes: $notes
        description: $description
      }
    ) {
      ${PROPERTY_MODEL}
    }
  }
`;

export const PROPERTY_UPDATE = gql`
  mutation propertyUpdate(
    $id: String
    $zip: String
    $state: String
    $street: String
    $city: String
    $crossStreets: String

    $size: Float
    $price: Float
    $status: String
    $nrBeds: Float
    $nrBaths: Float

    $agent: String
    $email: String
    $phone: String
    $slideIndex: Int

    $notes: String
    $description: String
  ) {
    propertyUpdate(
      property: {
        id: $id
        zip: $zip
        state: $state
        street: $street
        city: $city
        slideIndex: $slideIndex

        crossStreets: $crossStreets

        size: $size
        price: $price
        status: $status
        nrBeds: $nrBeds
        nrBaths: $nrBaths

        agent: $agent
        email: $email
        phone: $phone

        notes: $notes
        description: $description
      }
    ) {
      ${PROPERTY_MODEL}
    }
  }
`;

export const LIKED_PROPERTIES = gql`
  query likedProperties($skip: Int, $limit: Int) {
    likedProperties(skip: $skip, limit: $limit) {
      ${PROPERTY_MODEL}
    }
  }
`;

export const PURCHASED_PROPERTIES = gql`
  query purchasedProperties($skip: Int, $limit: Int, $iCloudUserId: String) {
    purchasedProperties(skip: $skip, limit: $limit, iCloudUserId: $iCloudUserId) {
      ${PROPERTY_MODEL}
    }
  }
`;

export const PENDING_PROPERTIES = gql`
  query pendingProperties($skip: Int, $limit: Int) {
    pendingProperties(skip: $skip, limit: $limit) {
      ${PROPERTY_MODEL}
    }
  }
`;

export const POPULAR_PROPERTIES = gql`
  query popularProperties($skip: Int, $limit: Int) {
    popularProperties(skip: $skip, limit: $limit) {
      ${PROPERTY_MODEL}
    }
  }
`;

export const PUBLISHED_PROPERTIES = gql`
  query publishedProperties($skip: Int, $limit: Int) {
    publishedProperties(skip: $skip, limit: $limit) {
      ${PROPERTY_MODEL}
    }
  }
`;

export const PROPERTY_GET = gql`
  query propertyGet($id: String) {
    propertyGet(id: $id){
      ${PROPERTY_MODEL}
    }
  }
`;

export const USER_PROPERTIES = gql`
  query userProperties($userId: String, $skip: Int, $limit: Int) {
    userProperties(userId: $userId, skip: $skip, limit: $limit) {
      ${PROPERTY_MODEL}
    }
  }
`;

export const SEARCH_PROPERTIES = gql`
  query searchProperties($keywords: String, $location: LocationInput, $skip: Int, $limit: Int) {
    searchProperties(keywords: $keywords, location: $location, skip: $skip, limit: $limit) {
      ${PROPERTY_MODEL}
    }
  }
`;
