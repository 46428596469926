import Grid from "@mui/material/Grid";
import React from "react";

export function Row(props) {
  return <Grid container direction="row" spacing={2} {...props} />;
}

export function Col(props) {
  return <Grid item xs {...props} />;
}
