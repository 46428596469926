import { Button, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { Heading1 } from "../../../components/typography";
import JoinLiveRoomView from "./JoinLiveRoomView";

import loadable from "@loadable/component";

const SignUpDialog = loadable(() => import("../../login/SignUpDialog/SignUpDialog"));

export default function HomeHeader() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [showSignUpDialog, setShowSignUpDialog] = React.useState(false);

  const headerStyle = matches ? {} : { fontSize: 35, lineHeight: "40px" };

  return (
    <div style={{ backgroundColor: "#0B4B76" }}>
      {showSignUpDialog && <SignUpDialog onClose={() => setShowSignUpDialog(false)} />}

      <Container
        style={{
          maxWidth: 1440,
          paddingTop: matches ? 175 : 100,
          paddingBottom: matches ? 120 : 20,
          textAlign: "center",
          backgroundSize: "contain",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          // backgroundImage: matches ? "url(/images/sample-bg.webp)" : "url(/images/sample-bg.webp)",
        }}>
        <Heading1
          style={{
            maxWidth: 1200,
            color: "#fff",
            marginLeft: "auto",
            lineHeight: "80px",
            marginRight: "auto",
            fontWeight: "bold",
            textAlign: "center",
            ...headerStyle,
          }}>
          Latest Articles
        </Heading1>
      </Container>
    </div>
  );
}
