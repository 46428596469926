import { gql } from "graphql-request";
import { USER_MODEL } from "../models";

export const ME = gql`
query me{
  me{
   ${USER_MODEL}
  }
}
`;

export const UPDATE_USER_MUTATION = gql`
  mutation userUpdate(
    $id: String
    $role: String
    $name: String
    $token: String
    $email: String
    $password: String
  ) {
    userUpdate(
      user: {
        id: $id
        name: $name
        role: $role
        token: $token
        email: $email
        password: $password
      }
    ) {
      ${USER_MODEL}
    }
  }
`;
