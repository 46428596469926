/* eslint-disable no-constant-condition */
import AccountCircle from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CardMembershipIcon from "@mui/icons-material/CreditCard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import { Container, useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useUser } from "../../app/store/appStore";
import { useSnackbar } from "../SnackbarHelper/SnackbarHelper";

import loadable from "@loadable/component";
import { removeToken } from "../../app/store/useLocalStorage";
import EditDocDialog from "../../features/docs/ui/EditDocDialog";

const SettingsDialog = loadable(() =>
  import("../../features/settings/SettingsDialog/SettingsDialog")
);

const SignInDialog = loadable(() => import("../../features/login/SignInDialog/SignInDialog"));
const SignUpDialog = loadable(() => import("../../features/login/SignUpDialog/SignUpDialog"));

export function HashLink({ children, style, ...props }) {
  return (
    <a
      {...props}
      style={{
        fontSize: 16,
        color: "#555",
        paddingLeft: 24,
        paddingRight: 24,
        cursor: "pointer",
        fontFamily: "Barlow",
        textDecoration: "none",
        ...style,
      }}>
      {children}
    </a>
  );
}

function Bar(props) {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useUser();

  const { hideLinks, canCreate } = props;
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [showLoginDialog, setShowLoginDialog] = React.useState(false);
  const [showSignUpDialog, setShowSignUpDialog] = React.useState(false);
  const [showEditDocDialog, setShowEditDocDialog] = React.useState(false);
  const [showSettingsDialog, setShowSettingsDialog] = React.useState(false);

  const [, setShowMessage] = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function signOut() {
    removeToken();
    setUser(null);

    setShowMessage("Signed out");
  }

  const drawerWidth = user ? 240 : 0;

  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      height: matches ? 85 : undefined,
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      borderBottom: "1.5px solid #F4F5F6",
      /*[theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },*/
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }));

  const classes = useStyles();

  return (
    <>
      <AppBar elevation={0} position="fixed" className={classes.appBar}>
        <Container style={{ maxWidth: 1440 }}>
          <Toolbar
            style={{
              maxWidth: 1440,
              paddingLeft: 0,
              paddingRight: 0,
              marginTop: matches ? 10 : 0,
            }}>
            {user && location.pathname === "/pricing" && (
              <Button
                color="primary"
                component="span"
                startIcon={<ArrowBackIcon />}
                onClick={() => history.push("/")}>
                Back to Dashboard
              </Button>
            )}

            {(location.pathname !== "/pricing" || !user) && (
              <Box display="flex" flexGrow={1} style={{ marginLeft: 8 }}>
                <img
                  alt="RV"
                  src="/logo192.webp"
                  style={{ width: 32, height: 32, marginRight: 4, marginLeft: matches ? 0 : -16 }}
                />

                <Typography
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    fontFamily: "Barlow",
                    textDecoration: "none",
                    fontSize: matches ? 24 : 18,
                  }}
                  color="inherit"
                  component={Link}
                  to="/">
                  RoomView {false && <span style={{ fontSize: 10 }}>Beta</span>}
                </Typography>
              </Box>
            )}

            {!hideLinks && matchesMdUp && !user && location.pathname !== "/pricing" ? (
              <Box display="flex" flexGrow={1}>
                <HashLink href="#how">How it works?</HashLink>
                <HashLink href="#faq">FAQ</HashLink>
                <HashLink href="/pricing">Pricing</HashLink>
                <HashLink href="/blog">Blog</HashLink>
              </Box>
            ) : (
              <Box display="flex" flexGrow={1}></Box>
            )}

            {!user && (
              <div color="inherit">
                {!matchesMdUp ? (
                  <Button
                    style={{ margin: 0, padding: 0 }}
                    size="small"
                    variant="text"
                    href="/pricing">
                    Pricing
                  </Button>
                ) : null}

                <Button
                  size="small"
                  variant="text"
                  style={{ margin: 0, padding: 0 }}
                  onClick={() => setShowLoginDialog(true)}>
                  Login
                </Button>

                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  style={{ margin: 0, padding: 0 }}
                  onClick={() => setShowSignUpDialog(true)}>
                  Sign up
                </Button>
              </div>
            )}

            {canCreate ? (
              <Button
                variant="contained"
                size={matches ? undefined : "small"}
                style={{ color: "#fff", maxWidth: 120 }}
                onClick={() => setShowEditDocDialog(true)}>
                <AddIcon style={{ fontSize: matches ? undefined : 20 }} />
                Create
              </Button>
            ) : null}

            {user && (
              <div>
                {user && (
                  <IconButton
                    size="large"
                    color="inherit"
                    onClick={handleMenu}
                    style={{ marginLeft: 8 }}>
                    <AccountCircle />
                  </IconButton>
                )}

                <Menu
                  keepMounted
                  {...{ open }}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setShowSettingsDialog(true);
                    }}>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary={user ? user.name || "Account" : "Account"} />
                  </MenuItem>

                  <MenuItem component={Link} to={"/pricing"}>
                    <ListItemIcon>
                      <CardMembershipIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Subscriptions"} />
                  </MenuItem>

                  <MenuItem onClick={signOut}>
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Sign out"} />
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      {showLoginDialog && <SignInDialog onClose={() => setShowLoginDialog(false)} />}
      {showSignUpDialog && <SignUpDialog onClose={() => setShowSignUpDialog(false)} />}
      {showEditDocDialog && <EditDocDialog onClose={() => setShowEditDocDialog(false)} />}
      {showSettingsDialog && <SettingsDialog onClose={() => setShowSettingsDialog(false)} />}
    </>
  );
}

export default Bar;
