import auth from "./auth";
import media from "./media";
import payment from "./payment";
import property from "./property";
import user from "./user";

export default {
  auth,
  user,
  media,
  payment,
  property,
};
