import Snackbar from "@mui/material/Snackbar";
import { atom, useAtom } from "jotai";
import React from "react";
import readingTime from "reading-time";

const AUTO_HIDE_DURATION = 2;

const snackbarAtom = atom("");

export function useSnackbar() {
  return useAtom(snackbarAtom);
}

export default function SnackbarHelper() {
  const [message, setMessage] = useSnackbar();

  if (!message) {
    return null;
  }

  return (
    <Snackbar
      open={!!message}
      message={message}
      onClose={() => setMessage("")}
      autoHideDuration={readingTime(message).time * AUTO_HIDE_DURATION}
    />
  );
}
