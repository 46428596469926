import { Container, Grid } from "@mui/material";
import React from "react";
import Colors from "../../../components/Colors";
import { BodyMedium, Heading5 } from "../../../components/typography";

function Feature({ title, image, text }) {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={4}
      lg={4}
      style={{ textAlign: "left", border: "0px solid blue" }}>
      <div style={{ textAlign: "center" }}>
        <img
          alt={title}
          src={image}
          style={{ width: 50, marginTop: 24, marginBottom: 10 }}
          width={50}
          height={50}
        />
      </div>
      <Heading5 style={{ margin: 0, color: "#555", textAlign: "center" }}>{title}</Heading5>
      <BodyMedium
        style={{
          color: Colors.Gray.G900,
          padding: 0,
          marginTop: 5,
          marginLeft: "auto",
          marginRight: "auto",
          fontSize: 16,
          marginBottom: 32,
          maxWidth: 280,
        }}>
        {text}
      </BodyMedium>
    </Grid>
  );
}
export default function HomeHow() {
  return (
    <>
      <Container style={{ paddingTop: 50, paddingBottom: 50, maxWidth: 1120 }}>
        <Grid container style={{ padding: 20 }}>
          <Feature
            title="What you get when you signup:"
            image="images/create.webp"
            text={`Do to a virtual tours during regular phone calls without interrupting the conversation, to answer prospect questions and close deals.`}
          />
          <Feature
            title="For who?"
            image="images/share.webp"
            text={`This benefits marketers, agents, and anyone who wants to showcase visuals during phone calls. Guide offers, share insights, and review documents together. RoomView.io aligns you and clients with real-time access to visual content.`}
          />
          <Feature
            title="Present"
            image="images/present.webp"
            text={`In essence, RoomView.io transforms remote presentations by seamlessly sharing info during phone calls. A game-changer for engagement, collaboration, and communication with clients and colleagues.`}
          />
        </Grid>
      </Container>
    </>
  );
}
