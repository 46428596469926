import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React from "react";
// import CookieConsent from "react-cookie-consent";
import { QueryClientProvider } from "react-query";
import ErrorBoundary from "../components/ErrorBoundary";
import SnackbarHelper from "../components/SnackbarHelper/SnackbarHelper";
import appearance from "../services/appearance";
import Api from "./api/Api";
import { queryClient } from "./api/client";
import Router from "./navigation/Router";
import { useUser } from "./store/appStore";
import { getToken, getViewerId, setToken } from "./store/useLocalStorage";

export default function App() {
  const [, setUser] = useUser();
  React.useEffect(() => {
    const token = getToken();
    const uuid = getViewerId();
    if (!uuid) {
      console.log({ uuid });
    }

    if (token) {
      Api.user
        .me()
        .then(setUser)
        .catch(() => setToken(""));
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={appearance.defaultTheme}>
          <CssBaseline />

          <ErrorBoundary>
            <Router />

            <SnackbarHelper />
          </ErrorBoundary>
          {/*false && (
            <CookieConsent
              location="bottom"
              cookieName={"accept-all-cookies"}
              buttonText="Accept all cookies"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}>
              Cookie tracking for the best roomview experience. By clicking “Accept all cookies”, you
              agree to the storing of cookies on your device to enhance site navigation, analyze site
              usage, and assist in our marketing efforts.
            </CookieConsent>
          )*/}
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
}
