import { gql } from "graphql-request";

export const DONT_CANCEL_MUTATION = gql`
  mutation dontCancel {
    dontCancel
  }
`;

export const UNSUBSCRIBE_MUTATION = gql`
  mutation unsubscribe {
    unsubscribe
  }
`;
