export const USER_MODEL = `
id
name
plan
role
email
stripeSubscriptionStatus
stripeSubscriptionCancelAt
`;

export const TASK_MODEL = `
id
note
done
due_date
createdAt
updatedAt
user {
    ${USER_MODEL}
}
handledBy {
    ${USER_MODEL}
}
`;

export const MEDIA_MODEL = `
id
url
type
text
ratio
width
height
duration
thumbnailUrl
`;

export const PROPERTY_MODEL = `
id,
zip,
state,
street,
city,
slideIndex
crossStreets,

size,
price,
status,
nrBeds,
nrBaths,

agent,
email,
phone,

notes,
description,

medias {
  ${MEDIA_MODEL}
}
user {
  ${USER_MODEL}
}
location {
  latitude
  longitude
}
createdAt
updatedAt
`;

export const BANK_MODEL = `
id
name
address
acc_mgr_name
acc_no
iban
swift
sortcode
`;

export const TIER_MODEL = `
id
name
`;

export const DOC_MODEL = `
id
url
name
pages {
    url
    fields{
        id
        x
        y
    }
}
fields{
    id
    name
    value
}
`;

export const OPERATOR_MODEL = `
id
name
aoc_acc
aoc_acc_expiry_date
ops_specs
ops_specs_expiry_date
`;

export const RATE_MODEL = `
id
name
value
`;

export const FUELVALIDITY_MODEL = `
id
name
`;

export const REGION_MODEL = `
id
name
margin1
margin2
margin3
`;

export const COUNTRY_MODEL = `
id
name
ovf_handler{
    id
    name
}
margin1
margin2
margin3
vat
duty

permit_application_form
fop_approval
crew_info
pax_info	
purpose_for_flight	
local_sponsor	
fir_entry_point	
fir_exit_point	
gen_dec
passenger_nationality	
passenger_manaifest	
operator_address	
copies_of_passenger_passports	
invitation_letter	
certificate_of_registration	
certificate_of_airworthiness	
certificate_of_insurance	
aoc_acc	
ops_specs	
certificate_of_airworthiness_review	
certificate_of_release_to_services	
noise_certificate 	
radio_license	
weight_and_balance_certificate	
techincal_logbook
pilots_license 	
pilots_medical

l_permit_application_form
l_fop_approval
l_crew_info
l_pax_info
l_purpose_for_flight
l_local_sponsor
l_fir_entry_point
l_fir_exit_point
l_gen_dec
l_passenger_nationality
l_passenger_manaifest
l_operator_address
l_copies_of_passenger_passports
l_invitation_letter
l_certificate_of_registration
l_certificate_of_airworthiness
l_certificate_of_insurance
l_aoc_acc
l_ops_specs
l_certificate_of_airworthiness_review
l_certificate_of_release_to_services
l_noise_certificate
l_radio_license
l_weight_and_balance_certificate
l_techincal_logbook
l_pilots_license
l_pilots_medical


region {
   ${REGION_MODEL}
}
`;

export const CITY_MODEL = `
id
name
country {
   ${COUNTRY_MODEL}
}
`;

export const VENDOR_MODEL = `
id
name

category
bank_details

use_portal,
portal_note,

website
handler_aftn
handler_sita
handling_vhf_freq

fuel_service
fuel_note

fuel_ops_fax
fuel_ops_whatsapp
fuel_ops_phone
fuel_ops_mobile
fuel_ops_emails
fuel_ops_manager

fuel_inv_fax
fuel_inv_whatsapp
fuel_inv_phone
fuel_inv_mobile
fuel_inv_emails
fuel_inv_manager

fuel_pricing_fax
fuel_pricing_whatsapp
fuel_pricing_phone
fuel_pricing_mobile
fuel_pricing_emails
fuel_pricing_manager

fuel_mgr_name
fuel_mgr_fax
fuel_mgr_whatsapp
fuel_mgr_emails
fuel_mgr_phone
fuel_mgr_mobile

overfly_service
overfly_note

overfly_ops_fax
overfly_ops_whatsapp
overfly_ops_phone
overfly_ops_mobile
overfly_ops_emails
overfly_ops_manager

overfly_inv_fax
overfly_inv_whatsapp
overfly_inv_phone
overfly_inv_mobile
overfly_inv_emails
overfly_inv_manager

overfly_pricing_fax
overfly_pricing_whatsapp
overfly_pricing_phone
overfly_pricing_mobile
overfly_pricing_emails
overfly_pricing_manager

overfly_country{
   ${COUNTRY_MODEL}
}

landing_service
landing_note

landing_ops_fax
landing_ops_whatsapp
landing_ops_phone
landing_ops_mobile
landing_ops_emails
landing_ops_manager

landing_inv_fax
landing_inv_whatsapp
landing_inv_phone
landing_inv_mobile
landing_inv_emails
landing_inv_manager

landing_pricing_fax
landing_pricing_whatsapp
landing_pricing_phone
landing_pricing_mobile
landing_pricing_emails
landing_pricing_manager

landing_icaos

handling_service
handling_note

handling_ops_fax
handling_ops_whatsapp
handling_ops_phone
handling_ops_mobile
handling_ops_emails
handling_ops_manager

handling_inv_fax
handling_inv_whatsapp
handling_inv_phone
handling_inv_mobile
handling_inv_emails
handling_inv_manager

handling_pricing_fax
handling_pricing_whatsapp
handling_pricing_phone
handling_pricing_mobile
handling_pricing_emails
handling_pricing_manager

handling_icaos

trans_service
trans_note

trans_ops_fax
trans_ops_whatsapp
trans_ops_phone
trans_ops_mobile
trans_ops_emails
trans_ops_manager

trans_inv_fax
trans_inv_whatsapp
trans_inv_phone
trans_inv_mobile
trans_inv_emails
trans_inv_manager

trans_pricing_fax
trans_pricing_whatsapp
trans_pricing_phone
trans_pricing_mobile
trans_pricing_emails
trans_pricing_manager

trans_icaos

catering_service
catering_note

catering_ops_fax
catering_ops_whatsapp
catering_ops_phone
catering_ops_mobile
catering_ops_emails
catering_ops_manager

catering_inv_fax
catering_inv_whatsapp
catering_inv_phone
catering_inv_mobile
catering_inv_emails
catering_inv_manager

catering_pricing_fax
catering_pricing_whatsapp
catering_pricing_phone
catering_pricing_mobile
catering_pricing_emails
catering_pricing_manager

catering_icaos

hotel_service
hotel_note

hotel_ops_fax
hotel_ops_whatsapp
hotel_ops_phone
hotel_ops_mobile
hotel_ops_emails
hotel_ops_manager
hotel_inv_fax
hotel_inv_whatsapp
hotel_inv_phone
hotel_inv_mobile
hotel_inv_emails
hotel_inv_manager
hotel_pricing_fax
hotel_pricing_whatsapp
hotel_pricing_phone
hotel_pricing_mobile
hotel_pricing_emails
hotel_pricing_manager

hotel_icaos

`;

export const OVERFLY_MODEL = `
id
status
remarks
handler {
    ${VENDOR_MODEL}
}
permitnr
permitdoc
internal_note
customer_remarks
country{
    ${COUNTRY_MODEL}
}
entryexit
`;

export const LANDING_MODEL = `
id
status
remarks
handler {
    ${VENDOR_MODEL}
}
permitnr
permitdoc
internal_note
customer_remarks
entryexit
`;

export const AIRPORT_MODEL = `
id
name
icao
iata
margin1
margin2
margin3
trans_handler{
    ${VENDOR_MODEL}
}
ground_handler{
    ${VENDOR_MODEL}
}
hotel_handler{
    ${VENDOR_MODEL}
}
catering_handler{
    ${VENDOR_MODEL}
}
landing_handler{
    ${VENDOR_MODEL}
}
city { 
    ${CITY_MODEL} 
}
`;

export const CUSTOMER_MODEL = `
id
name
status
company
billing_address
emails_ops
emails_fuel_team
emails_fuel_account
emails_fuel_update
emails_permit_account
payment_terms
airport_icaos
phone
phone1
contact_person

landing_fee
overflying_fee
supervision_fee1
supervision_fee2
supervision_fee3
supervision_fee4
disbursement_percent

bank {
    ${BANK_MODEL}
}
tier {
    ${TIER_MODEL}
}
`;

export const PASSENGER_MODEL = `
id,
name
captain
nationality
passport_nr
passport_url
passport_expiry_date
customer {
    ${CUSTOMER_MODEL}
}
`;

export const AIRCRAFT_MODEL = `
id
aircraft_reg
aircraft_type
mtow
mtow_unit
opt_type
homebase_icao
no_of_seats
serial_number

invitation_letter
invitation_letter_expiry_date
certificate_of_registration
certificate_of_registration_expiry_date
certificate_of_airworthiness
certificate_of_airworthiness_expiry_date
certificate_of_insurance
certificate_of_insurance_expiry_date
certificate_of_airworthiness_review
certificate_of_airworthiness_review_expiry_date
certificate_of_release_to_services
certificate_of_release_to_services_expiry_date
noise_certificate
noise_certificate_expiry_date
radio_license
radio_license_expiry_date
weight_and_balance_certificate
weight_and_balance_certificate_expiry_date
techincal_logbook
techincal_logbook_expiry_date


operator {
    ${OPERATOR_MODEL}
}
`;

export const FUEL_MODEL = `
id
note
status
remarks
fbo_handler
uplift_date
fuel_requested
uplift_date_tbc
airport_supplier
supplier {
    ${VENDOR_MODEL}
}
fuel_validity {
    ${FUELVALIDITY_MODEL}
}
`;

export const HANDLING_MODEL = `
  id
  ground_status,
  trans_status,
  hotel_status,
  catering_status,
  ground_handler{
    ${VENDOR_MODEL}
  }
  trans_handler{
    ${VENDOR_MODEL}
  }
  hotel_handler{
    ${VENDOR_MODEL}
  }
  catering_handler{
    ${VENDOR_MODEL}
  }
  basicGroundHandlingNote,
  basicGroundHandlingType,
  pprNote,
  arrivalAirportSlotNote,
  gpuNote,
  airStairsNote,
  beltLoaderNote,
  beltLoaderNr,
  hiLoader,
  hiLoaderNr,
  hiLoaderNote,
  hiLoaderNrOfDollies,
  toiletServiceNote,
  potableWaterServiceNote,
  gendecSubmissionNote,
  parkingNote,
  aircraftSecurityNote,
  hangarageNote,
  passengerSecurityGuardsNote,
  additionalServicesNote,
  basicGroundHandlingChecked,
  pprChecked,
  arrivalAirportSlotChecked,
  gpuChecked,
  airStairsChecked,
  beltLoaderChecked,
  hiLoaderChecked,
  toiletServiceChecked,
  potableWaterServiceChecked,
  gendecSubmissionChecked,
  parkingChecked,
  aircraftSecurityChecked,
  hangarageChecked,
  passengerSecurityGuardsChecked,
  additionalServicesChecked,
  basicGroundHandlingConfirmed,
  pprConfirmed,
  arrivalAirportSlotConfirmed,
  gpuConfirmed,
  airStairsConfirmed,
  beltLoaderConfirmed,
  hiLoaderConfirmed,
  toiletServiceConfirmed,
  potableWaterServiceConfirmed,
  gendecSubmissionConfirmed,
  parkingConfirmed,
  aircraftSecurityConfirmed,
  hangarageConfirmed,
  passengerSecurityGuardsConfirmed,
  additionalServicesConfirmed,
  passengerTransForArrivalChecked,
  passengerTransForArrivalConfirmed,
  passengerTransForDepartureChecked,
  passengerTransForDepartureConfirmed,
  passengerTransArrivalPickUpTime,
  passengerTransArrivalNrOfPassengers,
  passengerTransArrivalNrOfBags,
  passengerTransArrivalDropAddress,
  passengerTransArrivalArmedEscorted,
  passengerTransArrivalNote,
  passengerTransDeparturePickUpAddress,
  passengerTransDeparturePickUpTime,
  passengerTransDepartureNrOfPassengers,
  passengerTransDepartureNrOfBags,
  passengerTransDepartureArmedEscorted,
  passengerTransDepartureNote,
  passengerHotelChecked,
  passengerHotelConfirmed,
  passengerNrOfRooms,
  passengerHotelLocation,
  passengerHotelNote,
  passengerMinStarRating,
  passengerNameOfPreferredHotel,
  departureAirportSlotNote,
  cateringNote,
  nrOfPotsOfRegularCoffee,
  nrOfPotsOfDecafe,
  iceChecked,
  iceConfirmed,
  iceNrOfBags,
  iceNote,
  dryIceNrOfBags,
  departureBeltLoaderNote,
  departureBeltLoaderNr,
  departureHiLoader,
  departureHiLoaderNr,
  departureHiLoaderNote,
  departureHiLoaderNrOfDollies,
  pushbackNote,
  departureAirportSlotChecked,
  cateringChecked,
  coffeeChecked,
  coffeeNote,
  departureBeltLoaderChecked,
  departureHiLoaderChecked,
  pushbackChecked,
  departureAirportSlotConfirmed,
  cateringConfirmed,
  coffeeConfirmed,
  departureBeltLoaderConfirmed,
  departureHiLoaderConfirmed,
  pushbackConfirmed,
  crewTransForArrivalChecked,
  crewTransForDepartureChecked,
  crewTransForArrivalConfirmed,
  crewTransForDepartureConfirmed,
  crewTransArrivalPickUpTime,
  crewTransArrivalNrOfPassengers,
  crewTransArrivalNrOfBags,
  crewTransArrivalDropAddress,
  crewTransArrivalArmedEscorted,
  crewTransArrivalNote,
  crewTransDeparturePickUpAddress,
  crewTransDeparturePickUpTime,
  crewTransDepartureNrOfPassengers,
  crewTransDepartureNrOfBags,
  crewTransDepartureArmedEscorted,
  crewTransDepartureNote,
  crewHotelChecked,
  crewHotelConfirmed,
  crewHotelNote,
  crewNrOfRooms,
  crewHotelLocation,
  crewMinStarRating,
  crewNameOfPreferredHotel,
`;

export const LEG_MODEL = `
id
nr
etd
eta
icao
etd_tbc
eta_tbc
international
flight_number
flight_purpose
leg_passengers {
    type
    passenger {
        id
    }
}

fuel {
    ${FUEL_MODEL}
}
handling{
    ${HANDLING_MODEL}
}
landing{
    ${LANDING_MODEL}
}
overflys{
    ${OVERFLY_MODEL}
}
`;

export const REQUEST_MODEL = `
id
nr
note
updatedAt
user {
    ${USER_MODEL}
}
passengers {
    ${PASSENGER_MODEL}
}
customer_ref
legs {
    ${LEG_MODEL}
}
customer {
    id
    name
}
aircraft {
    ${AIRCRAFT_MODEL}
}
`;

export const REQUEST_MODEL_LOG = `
id
request {
    ${REQUEST_MODEL}
}
user{
    ${USER_MODEL}
}
leg {
    ${LEG_MODEL}
}
fuel {
    ${FUEL_MODEL}
}
message
text
html
createdAt
`;

export const FUELPRICE_MODEL = `
id
icao
into
taxes
notes
duties
min_usg
max_usg
other_fee
base_price
airport_fee
total_price
expiry_date
effective_date
supplier {
    ${VENDOR_MODEL}
}
`;

export const MARGIN_MODEL = `
id
customer {
    ${CUSTOMER_MODEL}
}
expiry_date
icao {
    ${AIRPORT_MODEL}
}
value
note
`;
