import { gql } from "graphql-request";
import { USER_MODEL } from "../models";

export const VERIFY_MUTATION = gql`
  mutation verify($email: String) {
    verify(email: $email)
  }
`;

export const UPLOAD_MUTATION = gql`
  mutation upload($base64: String, $filename: String) {
    upload(base64: $base64, filename: $filename)
  }
`;

export const VERIFY_CODE_MUTATION = gql`
  mutation verifyCode($email: String,  $code: String) {
    verifyCode(email: $email,code: $code){
      token
      user {
        ${USER_MODEL}
      }
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation login($email: String, $password: String) {
    login(email: $email, password: $password) {
      token
      user {
        ${USER_MODEL}
      }
    }
  }
`;

export const SIGNUP_MUTATION = gql`
  mutation signup($name: String, $email: String, $password: String) {
    signup(name: $name, email: $email, password: $password) {
      token
      user {
        ${USER_MODEL}
      }
    }
  }
`;

// Outdated
export const APPLICATION_MUTATION = gql`
  mutation application(
    $note: String
    $email: String
    $country: String
    $website: String
    $social1: String
    $social2: String
    $followers: String
  ) {
    application(
      note: $note
      email: $email
      country: $country
      website: $website
      social1: $social1
      social2: $social2
      followers: $followers
    )
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation updatePassword(
    $oldPassword: String,
    $newPassword: String
  ) {
    updatePassword(
      oldPassword: $oldPassword,
      newPassword: $newPassword
    ) {
      ${USER_MODEL}
    }
}
`;

export const SEND_EMAIL = gql`
  mutation sendEmail($to: [String], $cc: [String], $subject: String, $body: String) {
    sendEmail(to: $to, cc: $cc, body: $body, subject: $subject)
  }
`;
