import { Container, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { BodyMedium, Heading4 } from "../../../components/typography";

function useDeviceSize() {
  const theme = useTheme();

  const xs = useMediaQuery(theme.breakpoints.down("sm"));
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  if (xs) {
    return { isMobile: true };
  }

  if (sm) {
    return { isTablet: true };
  }

  return { isDesktop: true };
}

export default function Article1() {
  const { isMobile, isTablet } = useDeviceSize();

  let imageSize = isMobile || isTablet ? 200 : 300;

  return (
    <>
      <div id="how" style={{ marginTop: -80, marginBottom: 80 }} />

      <div
        style={{
          paddingTop: 50,
          paddingBottom: 32,
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "#fff",
        }}>
        <Container style={{ maxWidth: 1120, marginTop: 50 }}>
          <Grid
            container
            style={{
              borderRadius: 24,
              border: "1.5px solid #efefef",
            }}>
            {isMobile || isTablet ? (
              <Grid style={{ flex: 1, padding: 40, textAlign: "center" }}>
                <img
                  alt="presenter steps"
                  width={imageSize}
                  height={imageSize}
                  src="images/hero.webp"
                />
              </Grid>
            ) : null}

            <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: 40 }}>
              <Heading4
                style={{
                  fontSize: 32,
                  color: "#555",
                  textAlign: "left",
                  lineHeight: "38px",
                }}>
                Elevate Your Real Estate Tours
              </Heading4>
              <BodyMedium
                style={{
                  color: "#555",
                  padding: 0,
                  fontSize: 20,
                  marginTop: 24,
                  marginBottom: 5,
                }}>
                <p>
                  Have you ever found yourself on a phone call with a client, eager to show them a
                  property tour, but struggled with the limitations of traditional methods?
                </p>
                <p>
                  Sending pictures via email after the call often leads to disconnected
                  communication.
                </p>
                <p>
                  What if there were a seamless solution that allowed you to conduct remote property
                  tours without video calls or screensharing?
                </p>

                <p>
                  And what if these presentations could be attended by multiple people from
                  different locations?
                </p>

                <p>
                  Imagine a new way to captivate your clients – where they can effortlessly join
                  property tour in mere seconds. No need for any software installation or account
                  creation!
                </p>
                <p>
                  Introducing RoomView.io. a platform redefines how you conduct remote tours,
                  delivering captivating experiences that resonate long after the call ends.
                </p>
              </BodyMedium>
            </Grid>
            {!(isMobile || isTablet) ? (
              <Grid style={{ flex: 1, padding: 40, textAlign: "center" }}>
                <img
                  alt="presenter steps"
                  width={imageSize}
                  height={imageSize}
                  src="/images/hero.webp"
                />
              </Grid>
            ) : null}
          </Grid>
        </Container>
      </div>
    </>
  );
}
