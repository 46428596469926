export default {
  DOC: "doc",
  LEGS: "legs",
  DOCS: "docs",
  BANKS: "banks",
  TIERS: "tiers",
  RATES: "rates",
  STATS: "stats",
  TASKS: "tasks",
  CITIES: "cities",
  VENDORS: "vendors",
  REPORTS: "reports",
  REGIONS: "regions",
  MARGINS: "margins",
  REQUEST: "request",
  REQUEST_LOG: "log",
  AIRPORTS: "airports",
  HANDLERS: "handlers",
  LANDINGS: "landings",
  CUSTOMER: "customer",
  REQUESTS: "requests",
  AIRCRAFT: "aircraft",
  COUNTRIES: "countries",
  CUSTOMERS: "customers",
  SUPPLIERS: "suppliers",
  OPERATORS: "operators",
  HANDLINGS: "handlings",
  FUELPRICES: "fuelprices",
  PASSENGERS: "passengers",
  // property
  PROPERTY: "property",
  // place
  PLACE: "place",
  // properties
  USER_PROPERTIES: "userProperties",
  LIKED_PROPERTIES: "likedProperties",
  PURCHASED_PROPERTIES: "purchasedProperties",
  SEARCH_PROPERTIES: "searchProperties",
  POPULAR_PROPERTIES: "popularProperties",
  PUBLISHED_PROPERTIES: "publishedProperties",
  PENDING_PROPERTIES: "pendingProperties",
  FUELVALIDITIES: "fuelvalidities",
};
