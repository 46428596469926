import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export function SelectInput({ label, options, onValue, ...props }) {
  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel>{label}</InputLabel>
      <Select
        variant="outlined"
        {...{ label }}
        {...props}
        onChange={event => onValue(event.target.value)}>
        {options.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
