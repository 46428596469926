import client from "../client";
import { DONT_CANCEL_MUTATION, UNSUBSCRIBE_MUTATION } from "./graphql";

function dontCancel() {
  return client
    .mutate({
      variables: {},
      mutation: DONT_CANCEL_MUTATION,
    })
    .then(response => response.dontCancel);
}

function unsubscribe() {
  return client
    .mutate({
      variables: {},
      mutation: UNSUBSCRIBE_MUTATION,
    })
    .then(response => response.unsubscribe);
}

export default {
  dontCancel,
  unsubscribe,
};
