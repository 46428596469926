import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import HomeScreen from "../../features/home/HomeScreen";
import { useUser } from "../store/appStore";

import loadable from "@loadable/component";
import ArticleScreen from "../../features/blog/ArticleScreen";

const PricingScreen = loadable(() => import("../../features/pricing"));
const NotFoundScreen = loadable(() => import("../../features/notfound"));
const DocsScreen = loadable(() => import("../../features/docs/DocsScreen"));
const BlogScreen = loadable(() => import("../../features/blog/BlogScreen"));
const TermsScreen = loadable(() => import("../../features/legal/TermsScreen"));
const EditDocScreen = loadable(() => import("../../features/docs/EditDocScreen"));
const PrivacyScreen = loadable(() => import("../../features/legal/PrivacyScreen"));
const PresentScreen = loadable(() => import("../../features/present/PresentScreen"));

function Router() {
  const [user] = useUser();

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <div style={{ display: "flex", backgroundColor: "#f9fafa" }}>
        <main style={{ flexGrow: 1, backgroundColor: "#f9fafa" }}>
          <Switch>
            <Route path="/" exact>
              {user ? <DocsScreen /> : <HomeScreen />}
            </Route>

            <Route path="/docs">{user ? <DocsScreen /> : <Redirect to="/" />}</Route>

            <Route path="/blog/roomview-for-real-estate-agents">
              <ArticleScreen />
            </Route>

            <Route path="/blog">
              <BlogScreen />
            </Route>

            <Route path="/pricing">
              <PricingScreen />
            </Route>

            <Route path="/terms">
              <TermsScreen />
            </Route>

            <Route path="/privacy">
              <PrivacyScreen />
            </Route>

            <Route path="/editdoc/:docId">{user ? <EditDocScreen /> : <Redirect to="/" />}</Route>

            <Route path="/present/:docId">
              <PresentScreen />
            </Route>

            <Route>
              <NotFoundScreen />
            </Route>
          </Switch>
        </main>
      </div>
    </BrowserRouter>
  );
}

export default Router;
