import client from "../client";
import {
  LOGIN_MUTATION,
  SEND_EMAIL,
  SIGNUP_MUTATION,
  UPDATE_PASSWORD_MUTATION,
  UPLOAD_MUTATION,
  VERIFY_CODE_MUTATION,
  VERIFY_MUTATION,
} from "./graphql";

function upload(base64, filename) {
  return client
    .mutate({
      variables: {
        base64,
        filename,
      },
      mutation: UPLOAD_MUTATION,
    })
    .then(response => response.upload);
}

function verify(email) {
  return client
    .mutate({
      variables: { email },
      mutation: VERIFY_MUTATION,
    })
    .then(response => response.verify);
}

function verifyCode(email, code) {
  return client
    .mutate({
      variables: { email, code },
      mutation: VERIFY_CODE_MUTATION,
    })
    .then(response => response.verifyCode);
}

function login(email, password) {
  return client
    .mutate({
      variables: {
        email,
        password,
      },
      mutation: LOGIN_MUTATION,
    })
    .then(response => response.login);
}

function signup({ name, email, password }) {
  return client
    .mutate({
      variables: {
        name,
        email,
        password,
      },
      mutation: SIGNUP_MUTATION,
    })
    .then(response => response.signup);
}

function updatePassword(oldPassword, newPassword) {
  return client
    .mutate({
      variables: {
        oldPassword,
        newPassword,
      },
      mutation: UPDATE_PASSWORD_MUTATION,
    })
    .then(response => response.updatePassword);
}

function sendEmail({ to, cc, subject, body, attachments }) {
  return client
    .mutate({
      variables: {
        to,
        cc,
        subject,
        body,
        attachments,
      },
      mutation: SEND_EMAIL,
    })
    .then(response => response.sendEmail);
}

function form() {
  return client
    .mutate({
      variables: {},
      mutation: SEND_EMAIL,
    })
    .then(response => response.sendEmail);
}

export default {
  form,
  login,
  signup,
  upload,
  verify,
  sendEmail,
  verifyCode,
  updatePassword,
};
