import { Button, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { BodyMedium, Heading1 } from "../../../components/typography";
import JoinLiveRoomView from "./JoinLiveRoomView";

import loadable from "@loadable/component";

const SignUpDialog = loadable(() => import("../../login/SignUpDialog/SignUpDialog"));

export default function HomeHeader() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [showSignUpDialog, setShowSignUpDialog] = React.useState(false);

  const headerStyle = matches
    ? { fontSize: 60, lineHeight: "70px" }
    : { fontSize: 35, lineHeight: "40px" };
  const textStyle = matches ? {} : { fontSize: 14, maxWidth: 310 };

  return (
    <div style={{ backgroundColor: "#EEFBF9" }}>
      {showSignUpDialog && <SignUpDialog onClose={() => setShowSignUpDialog(false)} />}

      <Container
        style={{
          maxWidth: 1440,
          paddingTop: matches ? 175 : 100,
          paddingBottom: matches ? 120 : 20,
          textAlign: "center",
          backgroundSize: "contain",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          // backgroundImage: matches ? "url(/images/sample-bg.webp)" : "url(/images/sample-bg.webp)",
        }}>
        <Heading1
          style={{
            maxWidth: 1000,
            color: "#16181A",
            marginLeft: "auto",
            lineHeight: "80px",
            marginRight: "auto",
            fontWeight: "bold",
            textAlign: "center",
            ...headerStyle,
          }}>
          The only solution for real estate agents that lets you manage your listing and present
          them like a pro
        </Heading1>

        <Button
          variant="contained"
          onClick={() => setShowSignUpDialog(true)}
          style={{
            width: 220,
            height: 46,
            color: "#fff",
            marginRight: 8,
            letterSpacing: 1,
            marginTop: matches ? 27 : 10,
          }}>
          SIGNUP FREE NOW
        </Button>

        <BodyMedium
          style={{
            fontSize: 22,
            color: "#000",
            maxWidth: 560,
            marginTop: 10,
            marginBottom: 20,
            marginLeft: "auto",
            marginRight: "auto",
            ...textStyle,
          }}>
          Close more deals faster
        </BodyMedium>
      </Container>
    </div>
  );
}
