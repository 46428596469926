import loadable from "@loadable/component";
import { Button, Container, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { Heading2 } from "../../../components/typography";

const SignUpDialog = loadable(() => import("../../login/SignUpDialog/SignUpDialog"));

export default function HomePresentHassleFree() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [showSignUpDialog, setShowSignUpDialog] = React.useState(false);

  return (
    <Container style={{ maxWidth: 1120 }}>
      {showSignUpDialog && <SignUpDialog onClose={() => setShowSignUpDialog(false)} />}

      <Grid
        style={{
          marginTop: 32,
          minHeight: 270,
          maxWidth: 1120,
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "#eefbf9",
          border: "1.5px solid #E2FCF8",
          borderRadius: 24,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          textAlign: "center",

          // backgroundImage: matches ? "url(/images/cta.webp)" : "url(/images/cta.webp)",
        }}>
        <Heading2
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: 62,
            paddingLeft: matches ? 62 : 40,
            paddingRight: matches ? 62 : 40,
            marginBottom: 32,
            maxWidth: 610,
            ...(matches ? {} : { fontSize: 24 }),
          }}>
          A hassle-free presentation
        </Heading2>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setShowSignUpDialog(true)}
          style={{ color: "#fff", width: 160 }}>
          Sign up free
        </Button>
      </Grid>
    </Container>
  );
}
