/* eslint-disable */

import client from "../client";
import { ME, UPDATE_USER_MUTATION } from "./graphql";

function me() {
  return client
    .query({
      query: ME,
      variables: {},
    })
    .then(response => response.me);
}

function userUpdate({ id, name, token, email, password }) {
  return client
    .mutate({
      mutation: UPDATE_USER_MUTATION,
      variables: { id, name, token, email, password },
    })
    .then(response => response.userUpdate);
}

export default {
  me,
  userUpdate,
};
