import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import Api from "../../../app/api/Api";
import ResponsiveDialog from "../../../components/ResponsiveDialog/ResponsiveDialog";
import { useSnackbar } from "../../../components/SnackbarHelper/SnackbarHelper";
import { Col, Row } from "../../../components/grid";
import { SelectInput } from "./SelectInput";
import { MoneyInput } from "../../../components/inputs";

function EditDocDialog({ onClose, classes, ...props }) {
  const history = useHistory();
  const item = props.item || {};
  const [working, setWorking] = React.useState(false);

  const [id, setId] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [crossStreets, setCrossStreets] = React.useState("");

  const [price, setPrice] = React.useState("");
  const [size, setSize] = React.useState("");
  const [status, setStatus] = React.useState("Available");
  const [nrBeds, setNrBeds] = React.useState(0);
  const [nrBaths, setNrBaths] = React.useState(0);

  const [agent, setAgent] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");

  const [notes, setNotes] = React.useState("");
  const [description, setDescription] = React.useState("");

  const [, setShowMessage] = useSnackbar();

  React.useEffect(() => {
    if (item) {
      setId(item.id);
      setZip(item.zip);
      setCity(item.city);

      setSize(item.size);
      setPrice(item.price);
      setState(item.state);

      setAgent(item.agent);
      setEmail(item.email);
      setPhone(item.phone);
      setNotes(item.notes);
      setStreet(item.street);
      setStatus(item.status || "Available");
      setNrBeds(item.nrBeds || 0);
      setNrBaths(item.nrBaths || 0);

      setDescription(item.description);
      setCrossStreets(item.crossStreets);
    }
  }, [item]);

  async function onCreate() {
    setWorking(true);

    // const id = await getNextId();

    return Api.property
      .propertyCreate({
        zip,
        state,
        street,
        city,
        crossStreets,

        size: parseFloat(size) || 0,
        price: parseFloat(price) || 0,
        status,
        nrBeds,
        nrBaths,

        agent,
        email,
        phone,

        notes,
        description,

        index: 0,
      })
      .then(({ id }) => {
        setShowMessage("Presentation created!");
        history.push("/editdoc/" + id);
      })
      .then(() => onClose())
      .finally(() => setWorking(false));
  }

  async function onUpdate() {
    setWorking(true);

    return Api.property
      .propertyUpdate(id, {
        zip,
        state,
        street,
        city,
        crossStreets,

        size: parseFloat(size) || 0,
        price: parseFloat(price) || 0,
        status,
        nrBeds,
        nrBaths,

        agent,
        email,
        phone,

        notes,
        description,
      })
      .then(() => setShowMessage("Presentation updated!"))
      .then(() => onClose())
      .finally(() => setWorking(false));
  }

  function handleKeyPress(event) {
    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === "Enter") {
      if (id) {
        onUpdate();
      } else {
        onCreate();
      }
    }
  }

  return (
    <ResponsiveDialog open disableEscapeKeyDown={working} onKeyPress={handleKeyPress}>
      <DialogTitle>
        <Typography>&nbsp;</Typography>

        <Tooltip title="Close">
          <IconButton
            onClick={onClose}
            disabled={working}
            className={classes.closeButton}
            size="large">
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <Row style={{ marginTop: 1 }}>
          <Col>
            <TextInput label="Street" defaultValue={street} onValue={setStreet} />
          </Col>
          <Col>
            <TextInput label="City" defaultValue={city} onValue={setCity} />
          </Col>
        </Row>

        <Row style={{ marginTop: 1 }}>
          <Col>
            <TextInput label="State" defaultValue={state} onValue={setState} />
          </Col>
          <Col>
            <TextInput label="Zip" defaultValue={zip} onValue={setZip} />
          </Col>
        </Row>

        <Row style={{ marginTop: 1 }}>
          <Col>
            <TextInput
              label="Cross Streets"
              defaultValue={crossStreets}
              onValue={setCrossStreets}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 32 }}>
          <Col>
            <MoneyInput
              label="Price"
              defaultValue={price}
              onValue={v => {
                setPrice(v);
                console.log({ v });
              }}
            />
          </Col>
          <Col>
            <TextInput label="Size" defaultValue={size} onValue={setSize} />
          </Col>
          <Col>
            <SelectInput
              label="Status"
              value={status}
              onValue={setStatus}
              options={[
                "Available",
                "In Contract",
                "Application pending",
                "Sold",
                "Rented",
                "Off Market",
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 1 }}>
          <Col>
            <SelectInput
              label="Beds"
              value={nrBeds}
              onValue={setNrBeds}
              options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            />
          </Col>
          <Col>
            <SelectInput
              label="Baths"
              value={nrBaths}
              onValue={setNrBaths}
              options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 32 }}>
          <Col>
            <TextInput label="Agent" defaultValue={agent} onValue={setAgent} />
          </Col>
        </Row>

        <Row style={{ marginTop: 1 }}>
          <Col>
            <TextInput label="Email" defaultValue={email} onValue={setEmail} />
          </Col>
          <Col>
            <TextInput label="Phone" defaultValue={phone} onValue={setPhone} />
          </Col>
        </Row>

        <Row style={{ marginTop: 32 }}>
          <Col>
            <TextInput label="Description" defaultValue={description} onValue={setDescription} />
          </Col>
        </Row>

        <Row style={{ marginTop: 1 }}>
          <Col>
            <TextInput label="Additional notes" defaultValue={notes} onValue={setNotes} />
          </Col>
        </Row>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          color="primary"
          variant="outlined"
          disabled={working}
          onClick={id ? onUpdate : onCreate}>
          {item.id ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}

const styles = theme => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  icon: {
    marginRight: theme.spacing(0.5),
  },

  divider: {
    margin: "auto",
  },

  grid: {
    marginBottom: theme.spacing(2),
  },

  dialogActions: {
    margin: theme.spacing(2),
  },
});

export default withStyles(styles)(EditDocDialog);

function TextInput({ onValue, ...props }) {
  return (
    <TextField
      fullWidth
      {...props}
      variant="outlined"
      onChange={event => onValue(event.target.value)}
    />
  );
}
