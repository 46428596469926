import { GraphQLClient } from "graphql-request";
import { QueryClient } from "react-query";
import { getToken } from "../store/useLocalStorage";

// export const serverUrl = "http://192.168.1.180:4000"; /*
export const serverUrl = "https://roomview-1d14de10a846.herokuapp.com";
/**/

// next and previous legs are not mandatory for fuel
// ability to delete a leg if the serivce is not ordered or after cancellation
// show service at the dashboard level

export const queryClient = new QueryClient();

const client = new GraphQLClient(serverUrl, { headers: {} });

function query({ query: _query, variables }) {
  const headers = { authorization: getToken() };
  return client.request(_query, variables, headers).catch(body => {
    if (body.response && body.response.errors && body.response.errors.length > 0) {
      throw new Error(body.response.errors[0].message);
    }

    throw new Error("Something went wrong! ");
  });
}

function mutate({ variables, mutation }) {
  const headers = { authorization: getToken() };
  return client.request(mutation, variables, headers).catch(body => {
    if (body.response && body.response.errors && body.response.errors.length > 0) {
      throw new Error(body.response.errors[0].message);
    }

    throw new Error("Something went wrong!");
  });
}

export default {
  query,
  mutate,
  resetStore: () => queryClient.clear(),
};
