import { createTheme, adaptV4Theme } from "@mui/material/styles";
import Colors from "../components/Colors";

const defaultTheme = createTheme(
  adaptV4Theme({
    props: {
      MuiButton: {
        disableElevation: true,
      },
    },
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary: {
        light: "#757ce8",
        main: "#0B4B76",
        dark: "#002884",
        contrastText: "#fff",
      },
      secondary: {
        light: "#79C8EF",
        main: "#40A0D0",
        dark: "#0B4B76",
        contrastText: "#000",
      },
      success: {
        light: "#79C8EF",
        main: "#FD5E5A",
        dark: "#0B4B76",
        contrastText: "#000",
      },
      common: {
        black: "#16181A",
      },
    },
    typography: {
      fontFamily: "Barlow",
      button: {
        textTransform: "none",
      },
    },
    overrides: {
      MuiButton: {
        root: {
          paddingTop: 11,
          paddingLeft: 41,
          paddingRight: 41,
          borderRadius: 30,
          paddingBottom: 11,
          fontFamily: "Barlow",
          fontWeight: "bold",
        },
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
      body{
        backgroundColor: ${Colors.Gray.G50}
      }
       
      `,
      },
    },
  })
);

const blackAndWhiteTheme = createTheme(
  adaptV4Theme({
    props: {
      MuiButton: {
        disableElevation: true,
      },
    },
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary: {
        main: "#000", // Black
        contrastText: "#fff", // White
      },
      secondary: {
        main: "#fff", // White
        contrastText: "#000", // Black
      },
      success: {
        main: "#fff", // White
        contrastText: "#000", // Black
      },
      common: {
        black: "#000",
        white: "#fff",
      },
    },
    typography: {
      fontFamily: "Barlow",
      button: {
        textTransform: "none",
      },
    },
    overrides: {
      MuiButton: {
        root: {
          paddingTop: 11,
          paddingLeft: 41,
          paddingRight: 41,
          borderRadius: 30,
          paddingBottom: 11,
          fontFamily: "Barlow",
          fontWeight: "bold",
        },
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          body {
            background-color: ${Colors.Gray.G50}; // You can change this if needed
          }
        `,
      },
    },
  })
);

export default {
  defaultTheme: blackAndWhiteTheme,
};
