import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import "./index.css";
// import Tracker from "@openreplay/tracker";
/*
export const tracker = new Tracker({
  projectKey: "3rpDDYfucfn3G69SGI6C",
  // onStart: ({ sessionID }) => console.log("OpenReplay tracker started with session: ", sessionID),
});
tracker.start();
*/

ReactDOM.render(<App />, document.getElementById("root"));
